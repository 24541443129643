<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Title</label>
        <input v-model="section.Title" type="text" class="form-control" />
      </div>
    </div>
    <div class="col-md-12">
      <ImageInput v-model="section.BackgroundImg" label="Background image" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Padding" label="Padding" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.Color" label="Color" />
    </div>
    <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
  </div>
</template>

<script>
import ImageInput from '../../Coupons/Components/ImageInput'
import NumberInput from '../../Coupons/Components/NumberInput'
import ColorInput from '../../Coupons/Components/ColorInput'
import AlignInput from '../../Coupons/Components/AlignInput'

export default {
  components: {
    NumberInput,
    ImageInput,
    AlignInput,
    ColorInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style lang="scss">
.coupon-input-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>
