<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <div class="input-container">
      <input
        v-model="iValue"
        type="text"
        class="form-control"
        placeholder="example.com"
      />
      <button class="btn-upload" title="Upload" @click="selectFile">
        <b-spinner v-if="loadingUpload" small></b-spinner>
        <i v-else class="uil uil-image-upload"></i>
      </button>
      <button class="btn-upload" title="Images" @click="showImagesModal">
        <i class="uil uil-images"></i>
      </button>
      <button class="btn-upload btn-remove" title="Clear" @click="removeImage">
        <i class="uil uil-trash-alt"></i>
      </button>
      <input
        ref="inputFile"
        type="file"
        name="upload"
        style="display:none;"
        accept="image/*"
        @change="uploadFile($event)"
      />
    </div>
    <div ref="splashImages" class="splash-images" style="display:none;">
      <div class="input-container">
        <input
          v-model="query"
          type="text"
          class="form-control"
          placeholder="Search..."
          @keyup.enter="searchImages"
        />
        <button
          class="btn-upload"
          title="Images"
          :disabled="!query"
          @click="searchImages"
        >
          <b-spinner v-show="loadingImages" small></b-spinner>
          <i v-show="!loadingImages" class="uil uil-image-search"></i>
        </button>
      </div>

      <div class="list-images">
        <img
          v-for="(img, index) in splashImages"
          :key="index"
          :src="img"
          @click="selectImage(img)"
        />
      </div>

      <div v-if="splashImages.length" class="text-center mt-3">
        <button class="btn btn-outline-dark btn-sm" @click="loadMoreImages">
          <b-spinner v-show="loadingMoreImages" small></b-spinner>
          <span v-show="!loadingMoreImages">More images</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: [String],
      default: '',
    },
    label: {
      type: [String],
      default: '',
    },
  },

  data() {
    return {
      loadingUpload: false,
      loadingImages: false,
      loadingMoreImages: false,
      splashImages: [],
      query: '',
      page: 1,
    }
  },

  computed: {
    iValue: {
      get() {
        return this.value
      },

      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },

  methods: {
    chooseAlign(align) {
      this.$emit('input', align)
    },

    selectFile() {
      this.$refs.inputFile.click()
    },

    uploadFile(event) {
      if (!event.target.files.length) return

      let imageParams = new FormData()
      imageParams.append('file', event.target.files[0])

      this.loadingUpload = true
      this.$store
        .dispatch('coupon/uploadImage', imageParams)
        .then((imageUrl) => {
          this.$emit('input', imageUrl)
          this.loadingUpload = false
        })
        .catch(() => {
          this.loadingUpload = false
        })
    },

    loadMoreImages() {
      this.page += 1
      this.loadingMoreImages = true

      this.$store
        .dispatch('coupon/getUnsplashImages', {
          query: this.query,
          page: this.page,
        })
        .then((images) => {
          this.splashImages = [
            ...this.splashImages,
            ...images.map((img) => img.urls.regular),
          ]
          this.loadingMoreImages = false
        })
        .catch(() => {
          this.loadingMoreImages = false
        })
    },

    searchImages() {
      this.page = 1
      this.getImages()
    },

    getImages() {
      this.loadingImages = true

      this.$store
        .dispatch('coupon/getUnsplashImages', {
          query: this.query,
          page: this.page,
        })
        .then((images) => {
          this.splashImages = images.map((img) => img.urls.regular)
          this.loadingImages = false
          this.$refs.splashImages.style.display = 'block'
        })
        .catch(() => {
          this.loadingImages = false
        })
    },

    selectImage(img) {
      this.$emit('input', img)
    },

    toggleSplashImages(event) {
      let $el = event.target
      let clickOutSide = true

      do {
        if ($el && $el.className === 'splash-images') {
          clickOutSide = false
          break
        }
        $el = $el.parentNode
      } while ($el)

      if (clickOutSide) {
        this.$refs.splashImages.style.display = 'none'
        document.removeEventListener('click', this.toggleSplashImages)
      }
    },

    removeImage() {
      this.$emit('input', null)
    },

    showImagesModal(event) {
      event.stopPropagation()
      document.addEventListener('click', this.toggleSplashImages)

      if (!this.splashImages.length) {
        this.getImages()
      }

      this.$refs.splashImages.style.display = 'block'
    },
  },
}
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  .form-control {
    flex: 1 1 auto;
  }
}
.btn-upload {
  background: transparent;
  border: 1px solid #e2e7f1;
  margin-left: 5px;
  border-radius: 5px;
  padding: 0px 11px;
  color: #414141;
  cursor: pointer;
  &.btn-remove {
    color: #ec0303;
    border-color: #ec0303;
  }
}

.splash-images {
  position: absolute;
  max-width: 100%;
  width: 370px;
  background: #fff;
  border: 1px solid #e2e7f1;
  border-radius: 3px;
  box-shadow: 0px 0px 4px #e2e7f1;
  z-index: 50;
  padding: 10px;
  right: 12px;
  top: 80px;
  img {
    cursor: pointer;
    width: 100px;
    margin: 5px;
    border-radius: 3px;
    &:hover {
      opacity: 0.5;
    }
  }
  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e2e7f1;
    top: -10px;
    right: 58px;
  }
  &:after {
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid #fff;
    top: -9px;
    right: 60px;
  }

  .list-images {
    max-height: 340px;
    overflow-y: scroll;
  }
}
</style>
