<template>
  <div class="form-group" :style="{ marginBottom: marginBottom }">
    <label v-if="label">{{ label }} <span v-if="tooltip" v-b-tooltip.hover class="cursor-pointer pl-1" :title="tooltip"><i
          class="uil uil-question-circle"></i></span></label>
    <input v-model="iValue" type="number" class="form-control" :placeholder="placeholder" :max="max" :min="min" />
    <span class="unit" :style="{ top: unitTop }">{{ unit }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    label: {
      type: [String],
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    placeholder: {
      type: [String],
      default: '',
    },
    unit: {
      type: [String],
      default: 'px',
    },
    max: {
      type: [Number, String],
      default: undefined,
    },
    min: {
      type: [Number, String],
      default: undefined,
    },
    marginBottom: {
      type: String,
      default: '1rem'
    },
    unitTop: {
      type: String,
      default: '36px'
    }
  },

  computed: {
    iValue: {
      get() {
        return this.value
      },

      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;

  .unit {
    position: absolute;
    right: 8px;
  }

  input[type='number'] {
    padding-right: 25px;
  }
}
</style>
